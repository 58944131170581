*{
    margin:0;
    padding:0;
    
}

@keyframes moveUpDown {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }

  @keyframes scaleInOut {
    0%, 100% {
      transform: scale(0.97);
      opacity: 1;
    }
    50% {
      transform: scale(1);
      opacity: 1;
    }
  }

  .image1{
    width:50%;
  }

  @media (max-width: 750px) {
    .maindiv {
      flex-direction: column;
      gap:2rem;
      overflow-y:scroll;
      height:100%;
      justify-content: center;
      align-items: center;
      
    }

    .pic{
      margin-top: 2rem;
    }

    .writing{
      margin-top: 2rem;
      width:80%;
      /* overflow-y: auto; */
    }

    .navbox{
      height:2dvh;
    }

    .workex {
      flex-direction: column;
      overflow-y:scroll;
      gap: 2rem;
      width: 100%; /* Full width */
      height: auto; /* Adjust height based on content */
    }
  
    .Content {
      width: 90%; /* Adjusted for better responsiveness */
      margin: 2rem auto; /* Center horizontally */
    }

    .one, .two {
      width: 90%; /* Full width for stacking in column layout */
      /* margin:1rem; */
    }

    .skills{
      padding:0rem;
      margin:2rem;
    }

    .heading{
      width:100%;
    }

    .heading h1{
      text-align: center;
    }

    .navlinks {
      flex-direction: row; /* Ensure horizontal layout */
      overflow-x: auto; /* Enable horizontal scrolling if needed */
      white-space: nowrap; /* Prevent text wrapping */
      padding: 0 1rem; /* Horizontal padding for better spacing */
      width: 100%; /* Full width of the container */
      box-sizing: border-box; /* Include padding in width calculation */
    }
  
    .navlinks a {
      flex: 0 0 auto; /* Prevent links from shrinking */
      gap:0.2rem;
    }
  
    .navlinks .MuiButton-root {
      white-space: nowrap; /* Prevent text wrapping within buttons */
    }

    .multiproject{
      flex-direction: column;
      overflow-y: scroll;
      height:auto;
      gap: 2rem;
      width: 80%; /* Full width */
    }

    .content{
      width:70%;
    }

    .image1{
      width:100%;
    }

    .image{
      width: 100%;
    }
  }
  
  